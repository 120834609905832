































































































import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import { ping } from "@/api/ping";

@Component({
  name: "Login",
  filters: {
    pingStatus: (status: number) => {
      if (status == 1) return "Serveur en ligne";
      else if (status == 2) return "Serveur indisponible";
      else return "Tentative de connexion au serveur MOTS";
    },
  },
})
export default class extends Vue {
  //#region Reference
  @Ref("password") readonly password!: HTMLInputElement;
  @Ref("buttonLogin") readonly buttonLogin!: HTMLButtonElement;
  @Ref("form") readonly elForm!: HTMLFormElement;
  //#endregion

  //#region Data
  private loginForm = {
    username: "",
    password: "",
  };
  private passwordType = "password";
  private loading = false;
  private formValidated = false;
  private pingEnabled = 0;
  //#endregion

  //#region Computed
  get pingStatusVariant() {
    if (this.pingEnabled == 1) return "success";
    else if (this.pingEnabled == 2) return "danger";
    else return "secondary";
  }
  //#endregion

  //#region Hooks
  async mounted() {
    try {
      await ping();
      this.pingEnabled = 1;
    } catch (error) {
      this.pingEnabled = 2;
    } finally {
      this.loading = false;
    }
  }
  //#endregion

  //#region Methods
  // Afficher / masquer le mot de passe
  private showPwd() {
    if (this.passwordType === "password") {
      this.passwordType = "";
    } else {
      this.passwordType = "password";
    }
    this.$nextTick(() => {
      this.password.focus();
    });
  }

  // Tentative de connexion
  private async login() {
    //console.log(this.loginForm);
    // Vérifier la validité des champs
    if (this.elForm.checkValidity() === false) {
      this.formValidated = true;
    } else {
      this.loading = true;
      try {
        await UserModule.Login(this.loginForm);
        this.$router.push({
          path: "/",
        });
      } finally {
        this.loading = false;
      }
    }
  }

  // Re-donne le focus au bouton
  onHidden() {
    this.buttonLogin.focus();
  }
  //#endregion
}
